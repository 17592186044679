import React from 'react';
import AUProducts from './AUProducts';

function AddProducts(props) {
    return (
        <div>
            <AUProducts type="add-product" />
        </div>
    );
}

export default AddProducts;